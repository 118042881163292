import React from "react";
import Data from "../../data";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Link } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  games: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: "column"
  },
  game: {},
  logo: {
    maxWidth: "80%",
    height: "auto"
  }
}));

export default function Games() {
  const classes = useStyles();
  const games = Data["games"];

  return (
    <React.Fragment>
      <div className={classes.games}>
        {/* <Typography variant="h2">Games</Typography> */}
        {games.map(games => (
          <Link href={games.link}>
            <Typography>
              <img
                src={games.logo}
                alt={games.name}
                className={classes.logo}
              ></img>
            </Typography>
          </Link>
        ))}
      </div>
    </React.Fragment>
  );
}
