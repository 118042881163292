import React from "react";
import "./App.css";
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider
} from "@material-ui/core/styles";
import Data from "./data";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Fade } from "@material-ui/core";
import FollowAt from "react-social-media-follow";
import Obfuscate from "react-obfuscate";
import Games from "./components/games/Games";

let theme = createMuiTheme({
  status: {
    danger: "orange"
  },
  typography: {
    fontFamily: "Reem Kufi, Arial"
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": ["Reem Kufi"]
      }
    }
  }
});

theme = responsiveFontSizes(theme);

const useStyles = makeStyles(theme => ({
  "@global": {
    a: {
      textDecoration: "none",
      outline: 0
    },
    "a:link": {
      color: "white"
    },
    "a:visited": {
      color: "white"
    },
    "a:hover": {
      color: "rgb(195, 4, 4)"
    },
    "a:active": {
      color: "#A30404"
    }
  },
  white: {
    color: "#FFFFFF"
  },
  heads: {
    color: "rgb(195, 4, 4)"
  },
  studios: {
    fontSize: 64,
    display: "flex",
    justifyContent: "flex-end"
  },
  logoBox: {
    display: "flex",
    flexDirection: "center",
    fontWeight: "1rem"
  },
  contact: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column"
  }
}));

function App(props) {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <header className="App-header" style={{ background: "#000000" }}>
          <div className={classes.logoBox}>
            <Fade in timeout={500}>
              <Typography variant="h1">
                <div>
                  <span className={classes.white}>
                    chemical <span className={classes.heads}>heads</span>
                  </span>
                </div>
                <div className={classes.studios}>studios</div>
              </Typography>
            </Fade>
          </div>
          <Fade in timeout={1000}>
            <div>
              <div className={classes.contact}>
                <FollowAt links={Data["social_media"]} />
                <p>
                  <Obfuscate email={Data["email"]} />
                </p>
              </div>
              <Games></Games>
            </div>
          </Fade>
        </header>
      </div>
    </ThemeProvider>
  );
}

export default App;
